<template>
  <div class="one-off-schedule">
    <div class="schedule-tab-item-header custom-grey-border p-3">
      <v-layout>
        <v-flex>
          <h3 class="m-0 text-uppercase font-weight-700 color-custom-blue">
            Team
          </h3>
        </v-flex>
        <v-flex class="text-right">
          <template v-if="db_assigned_team.length">
            <v-menu
              bottom
              left
              offset-x
              offset-y
              origin="center center"
              transition="scale-transition"
              max-width="350px !important"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mx-2 custom-bold-button white--text"
                  color="cyan"
                  right
                  depressed
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon class="mr-2">mdi-account-group</v-icon>Assign
                </v-btn>
              </template>
              <v-list class="light-gray-background pb-0" min-width="340">
                <v-list-item-title
                  class="text-uppercase font-weight-700 color-custom-blue font-size-16 p-2 pt-0"
                >
                  Select Technician
                </v-list-item-title>
                <v-list-item
                  class="white-background"
                  v-for="(team, index) in db_assigned_team"
                  :key="`team-checkbox-${index}`"
                >
                  <v-checkbox
                    color="cyan"
                    class="my-0 mx-0 py-0 px-0"
                    v-model="team.assign_status"
                    v-on:change="updateTeam(team)"
                  >
                    <template v-slot:label>
                      <v-list-item>
                        <v-list-item-avatar>
                          <v-img
                            :lazy-src="$defaultProfileImage"
                            :src="$assetAPIURL(team.profile_logo)"
                          ></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title
                            class="font-size-16 font-weight-500 text-truncate"
                            style="max-width: 200px"
                            v-html="`${team.display_name} (${team.barcode})`"
                          >
                          </v-list-item-title>
                          <!--  <v-list-item-subtitle v-if="team.designation">
                            {{ team.designation }}
                          </v-list-item-subtitle> -->
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-checkbox>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <v-btn
            v-else
            v-on:click="getAvailableTeam()"
            color="red lighten-2 white--text"
            class="custom-bold-button red-glowing-btn"
            depressed
            ><v-icon class="mr-2">mdi-account-group</v-icon>Get Available</v-btn
          >
        </v-flex>
      </v-layout>
    </div>
    <div
      class="schedule-tab-item-body custom-grey-border p-3"
      style="min-height: 165px"
    >
      <template v-if="assigned_team && assigned_team.length">
        <v-list-item
          v-for="(team, index) in assigned_team"
          :key="`assigned-team-${index}`"
        >
          <v-list-item-avatar>
            <v-img
              :lazy-src="$defaultProfileImage"
              :src="$assetAPIURL(team.profile_logo)"
            ></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title
              class="font-size-16 font-weight-500 text-truncate"
              style="max-width: 200px"
              v-html="`${team.display_name} (${team.barcode})`"
            ></v-list-item-title>
            <!--   <v-list-item-subtitle v-if="team.designation">
              {{ team.designation }}
            </v-list-item-subtitle> -->
          </v-list-item-content>
          <v-list-item-icon link v-on:click="removeTeam(team.barcode, index)">
            <v-btn icon color="red lighten-1">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-list-item-icon>
        </v-list-item>
        <!--  <v-checkbox
          class="mt-3 mb-0 mx-0 py-0 px-0"
          color="cyan"
          v-model="email_team"
          label="Email team about assignment?"
        /> -->
      </template>
      <template v-else>
        <p
          class="m-0 p-4 font-size-16 font-weight-500 light-gray-background custom-grey-border d-flex align-center"
        >
          <v-icon class="mr-1">mdi-alert-circle-outline</v-icon>Oops... No
          technician are currently assigned.
        </p>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  GET_AVAILABLE_TEAM,
  SET_VISIT_TEAM,
} from "@/core/services/store/visit.module";
import { ErrorEventBus } from "@/core/lib/message.lib";
import moment from "moment-timezone";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  name: "visit-team",
  data() {
    return {
      assigned_team: [],
      email_team: true,
      loading: false,
      db_assigned_team: [],
    };
  },
  methods: {
    getAvailableTeam() {
      let schedule = {};
      if (this.vScheduleType == "one-off") {
        schedule = this.vSchedule;
      }
      if (this.vScheduleType == "recurring") {
        schedule = this.vRecurringSchedule;
      }
      if (!schedule.start_date) {
        ErrorEventBus.$emit("update:error", "Please Select Start Date.");
        return false;
      }
      if (!schedule.start_time) {
        ErrorEventBus.$emit("update:error", "Please Select Start Time.");
        return false;
      }
      if (!schedule.end_date) {
        ErrorEventBus.$emit("update:error", "Please Select End Date.");
        return false;
      }
      if (!schedule.end_time) {
        ErrorEventBus.$emit("update:error", "Please Select End Time.");
        return false;
      }
      let request = {
        start_date: moment(schedule.start_date).format("YYYY-MM-DD"),
        end_date: moment(schedule.end_date).format("YYYY-MM-DD"),
        start_time: moment(schedule.start_time, ["hh:mm A"]).format("HH:mm"),
        end_time: moment(schedule.end_time, ["hh:mm A"]).format("HH:mm"),
        force: 0,
        all: 1,
        schedule: this.vScheduleType,
      };
      this.loading = true;
      this.$store
        .dispatch(GET_AVAILABLE_TEAM, request)
        .then((output) => {
          console.log({ output });
        })
        .catch((error) => {
          console.log({ error });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    emitInput() {
      this.$nextTick(() => {
        this.$store.dispatch(SET_VISIT_TEAM, {
          engineer: this.assigned_team,
          email_team: this.email_team,
        });
      });
    },
    removeTeam(barcode, index) {
      let dindex = this.lodash.findIndex(this.db_assigned_team, { barcode });

      if (dindex > -1) {
        this.db_assigned_team[dindex].assign_status = false;
      }

      this.assigned_team.splice(index, 1);
      // this.db_assigned_team
      this.emitInput();
    },
    updateTeam(row) {
      if (row.assign_status) {
        this.assigned_team.push({ ...row });
        this.emitInput();
      } else {
        this.lodash.remove(this.assigned_team, function (rw) {
          return rw.id == row.id;
        });
        this.emitInput();
      }
    },
  },
  watch: {
    vTeam: {
      deep: true,
      handler(param) {
        this.assigned_team = this.lodash.cloneDeep(param.engineer);
        this.email_team = this.lodash.cloneDeep(param.email_team);
      },
    },
    vAvailableTeam: {
      deep: true,
      immediate: true,
      handler(param) {
        this.db_assigned_team = this.lodash.cloneDeep(param);
        this.assigned_team = [];
      },
    },
  },

  computed: {
    ...mapGetters([
      "vTeam",
      "vSchedule",
      "vScheduleType",
      "vRecurringSchedule",
      "vAvailableTeam",
    ]),
  },
};
</script>
