<template>
  <tr>
    <td>
      <div class="d-flex flex-wrap">
        <div style="width: 160px">
          <v-checkbox
            v-model="all_reminder"
            v-on:click="selectAll(all_reminder)"
            class="mr-2"
            color="cyan white--text"
            label="All"
          />
        </div>
        <div style="width: 160px">
          <v-checkbox
            v-model="oneMonth_reminder"
            v-on:click="selectChild(oneMonth_reminder)"
            class="mr-2"
            color="cyan white--text"
            label="One Month Before"
          />
        </div>
        <div style="width: 160px">
          <v-checkbox
            v-model="fifteenday_reminder"
            v-on:click="selectChild(fifteenday_reminder)"
            class="mr-2"
            color="cyan white--text"
            label="15 Day Before"
          />
        </div>
        <div style="width: 160px">
          <v-checkbox
            v-model="oneweek_reminder"
            v-on:click="selectChild(oneweek_reminder)"
            class="mr-2"
            color="cyan white--text"
            label="One Week Before"
          />
        </div>
        <div style="width: 160px">
          <v-checkbox
            v-model="oneday_reminder"
            v-on:click="selectChild(oneday_reminder)"
            class="mr-2"
            color="cyan white--text"
            label="One Day Before"
          />
        </div>
        <div style="width: 160px">
          <v-checkbox
            v-model="onday_reminder"
            v-on:click="selectChild(onday_reminder)"
            class="mr-2"
            color="cyan white--text"
            label="On Day"
          />
        </div>
        <div style="width: 160px">
          <v-checkbox
            v-model="ondayafter_reminder"
            v-on:click="selectChild(ondayafter_reminder)"
            class="mr-2"
            color="cyan white--text"
            label="One Day After"
          />
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import { mapGetters } from "vuex";
import { isEmpty } from "lodash";
export default {
  props: {
    classLabel: {
      type: String,
      default: "remiderCustom",
    },
    type: {
      type: String,
      default: null,
    },
    reminderObj: {
      type: Object,
      default() {
        return new Object();
      },
    },
  },
  data() {
    return {
      all_reminder: false,
      oneMonth_reminder: false,
      fifteenday_reminder: false,
      oneweek_reminder: false,
      oneday_reminder: false,
      onday_reminder: false,
      ondayafter_reminder: false,
    };
  },
  watch: {
    reminderObj: {
      deep: true,
      immediate: true,
      handler(param) {
        this.filterReminderData(param);
        if (isEmpty(this.$route.query) === true) {
          this.all_reminder = true;
          this.selectAll(true);
        }
      },
    },
  },
  methods: {
    selectAll(checkbox) {
      if (checkbox) {
        (this.oneMonth_reminder = true),
          (this.fifteenday_reminder = true),
          (this.oneweek_reminder = true),
          (this.oneday_reminder = true),
          (this.onday_reminder = true),
          (this.ondayafter_reminder = true);
      } else {
        (this.oneMonth_reminder = false),
          (this.fifteenday_reminder = false),
          (this.oneweek_reminder = false),
          (this.oneday_reminder = false),
          (this.onday_reminder = false),
          (this.ondayafter_reminder = false);
      }
      this.emitReminderData();
    },
    emitReminderData() {
      this.$emit("update:data", {
        all_reminder: this.all_reminder,
        oneMonth_reminder: this.oneMonth_reminder,
        fifteenday_reminder: this.fifteenday_reminder,
        oneweek_reminder: this.oneweek_reminder,
        oneday_reminder: this.oneday_reminder,
        onday_reminder: this.onday_reminder,
        ondayafter_reminder: this.ondayafter_reminder,
      });
    },
    selectChild(notcheck) {
      if (
        this.oneMonth_reminder &&
        this.fifteenday_reminder &&
        this.oneweek_reminder &&
        this.oneday_reminder &&
        this.onday_reminder &&
        this.ondayafter_reminder
      ) {
        this.all_reminder = true;
      }
      if (!notcheck) {
        this.all_reminder = false;
      }
      this.emitReminderData();
    },
    filterReminderData(array) {
      if (isEmpty(array) === false && this.type) {
        (this.all_reminder =
          array && array[this.type] && array[this.type].all_reminder),
          (this.oneMonth_reminder =
            array && array[this.type] && array[this.type].oneMonth_reminder),
          (this.fifteenday_reminder =
            array && array[this.type] && array[this.type].fifteenday_reminder),
          (this.oneweek_reminder =
            array && array[this.type] && array[this.type].oneweek_reminder),
          (this.oneday_reminder =
            array && array[this.type] && array[this.type].oneday_reminder),
          (this.onday_reminder =
            array && array[this.type] && array[this.type].onday_reminder),
          (this.ondayafter_reminder =
            array && array[this.type] && array[this.type].ondayafter_reminder);
      }
    },
  },
  components: {},
  mounted() {
    /* if(isEmpty(this.reminderObj) === true){
         this.all_reminder = true;
         this.selectAll(true)
      } */
  },
  computed: {
    ...mapGetters(["vReminder"]),
  },
};
</script>
